<template>
  <component :is="`style`">
    <!-- Api driven styles -->
    .presentor-container {
      background: {{background}};
    }
    .event-title {
      color: {{tabFontColor}} !important;
      background-color: {{tabBackground}};
    }
    .border-top {
      border-top: 1px solid {{tabBackground}} !important;
    }
    .border-bottom {
      border-bottom: 1px solid {{tabBackground}} !important;
    }
  </component>
  <div class="modal is-flex" v-if="allowed === false">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Please login</p>
          </div>
        </div>
        <div class="modal-actions">
          <div class="form-container">
            <form @submit.prevent="login">
              <div class="field">
                <label class="label" for="login-pass">Password:</label>
                <div class="control">
                  <input tabindex="2" class="input" id="login-pass" type="password" autocomplete="true" v-model="enteredPassword"/>
                </div>
              </div>
              <button tabindex="3" class="button is-info is-fullwidth" type="submit" :disabled="enteredPassword === ''">
                Enter
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
  <main class="presentor-view" v-else>
    <div class="presentor-container">
      <div class="event-title">
        <p v-if="session?.id === 0">{{session?.eventName || ''}} Live chat (Presenter)</p>
        <p v-if="session?.id === 1">{{session?.eventName || ''}} Q/A (Presenter)</p>
      </div>
      <div class="comments-section">
        <p class="comment-title" v-if="session?.id === 0">Current Comments</p>
        <p class="comment-title" v-if="session?.id === 1">Current Questions</p>
        <p class="new-message" v-if="newMessageFlag">New Message!</p>
        <ul class="mt-3 is-flex is-flex-direction-column" id="out" @scroll="handleScroll">
          <li v-for="comment of session.contents" :key="comment" v-bind:class="{'hidden': comment?.status !== 'approved'}">
            <div class="comment" v-if="comment?.status === 'approved'" v-on:mouseover="comment.isNew = false;">
              <p class="comment-content" v-if="comment?.name !== ''">{{comment?.name}} <b>{{comment?.comment}}</b></p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/database';

export default {
  name: 'presentor',
  data() {
    return {
      sessionID: '',
      event: {},
      session: {},
      allowed: false,
      enteredPassword: '',
      password: '',
      // colors
      background: '',
      tabBackground: '',
      tabFontColor: '',
      tabActiveBackground: '',
      tabActiveFontColor: '',
      buttonBackground: '',
      buttonTextColor: '',
      buttonActiveBackgroundColor: '',
      buttonActiveFontColor: '',
      commentFontColor: '',
      commentBackgroundColor: '',
      // Scrolling
      scrollToBottom: true,
      initialScrollHeight: 0,
      // New states
      newMessageFlag: false,
      showNewState: false,
    };
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.sessionID = window.location.href.split('/').slice(-1)[0];
    // Let's get the event from the session
    firebase.database().ref(`sessions/${this.sessionID}`)
      .on('value', (snapshot) => {
        if (snapshot.exists()) {
          const session = snapshot.val();
          if (session.id === 0) {
            if (session.comments) {
              // eslint-disable-next-line no-param-reassign
              session.contents = Object.values(session.comments);
            } else {
              // eslint-disable-next-line no-param-reassign
              session.contents = [];
            }
            session.contents.sort((a, b) => new Date(a.date) - new Date(b.date));
          }
          if (session.id === 1) {
            if (session.questions) {
              // eslint-disable-next-line no-param-reassign
              session.contents = Object.values(session.questions);
            } else {
              // eslint-disable-next-line no-param-reassign
              session.contents = [];
            }
            [].sort.call(session.questions, ((a, b) => new Date(a.date) - new Date(b.date)));
          }
          setTimeout(() => {
            this.session = session;
            this.checkForPassword(session.eventID);
            this.scrolling();
          }, 100);
        } else {
          console.info('No session per this id');
          window.location.href = 'https://www.huddlearea.com/';
        }
      });
  },
  methods: {
    checkForPassword(eventID) {
      try {
        firebase.database().ref(`events/${eventID}`).on('value', (snapshot) => {
          if (snapshot.exists()) {
            this.event = snapshot.val();
            this.setEventStyling(snapshot.val());
            if (snapshot.val().presenterPassword === '' || snapshot.val().presenterPassword === ' ') {
              this.password = atob(snapshot.val().presenterPassword);
              this.allowed = true;
            } else {
              this.password = atob(snapshot.val().presenterPassword);
            }
          } else {
            console.info('No event per this id');
            window.location.href = 'https://www.huddlearea.com/';
          }
        });
      } catch (e) {
        console.error(`Error getting event information by id: ${e}`);
      }
    },
    // Login
    login() {
      try {
        if (this.enteredPassword === this.password) {
          this.allowed = true;
          this.scrolling();
          this.setNewFlag();
        } else {
          this.allowed = false;
        }
      } catch (e) {
        console.error(`Problem login in: ${e}`);
      }
    },
    // Helper functions
    setEventStyling(data) {
      // font size
      this.fontSize = data.fontSize;
      // Color settings
      this.background = data.backgroundColor;
      this.tabFontColor = data.tabFontColor;
      this.tabBackground = data.tabBackground;
      this.tabActiveBackground = data.tabActiveBackground;
      this.tabActiveFontColor = data.tabActiveFontColor;
      this.buttonBackground = data.buttonBackground;
      this.buttonTextColor = data.buttonTextColor;
      this.buttonActiveBackgroundColor = data.buttonActiveBackgroundColor;
      this.buttonActiveFontColor = data.buttonActiveFontColor;
      this.commentFontColor = data.chatTextBackground;
      this.commentBackgroundColor = data.chatMessageBackground;
    },
    // Scrolling functions
    setNewFlag() {
      setTimeout(() => {
        if (this.session.id === 0) {
          const ref = firebase.database().ref(`sessions/${this.session.sessionID}`);
          const comments = ref.child('comments');
          comments.on('child_added', (dataSnapshot) => {
            const commentChanged = dataSnapshot.val();
            if (commentChanged.status === 'approved') {
              setTimeout(() => {
                const out = document.getElementById('out');
                if (out.scrollHeight < 1200) {
                  // Do nothing
                } else {
                  if (Math.ceil(out.scrollTop) === 0) {
                    console.log('Top');
                    this.newMessageFlag = true;
                  }
                  const middle = Math.ceil(out.scrollHeight / 2);
                  if (Math.ceil(out.scrollTop) <= middle) {
                    console.log('Middle');
                    this.newMessageFlag = true;
                  }
                  const check = Math.ceil(out.scrollHeight - out.clientHeight);
                  if (Math.ceil(out.scrollTop) >= check) {
                    this.newMessageFlag = false;
                    console.log('Bottom');
                  }
                }
              }, 200);
            }
          });
        }
        if (this.session.id === 1) {
          const ref = firebase.database().ref(`sessions/${this.session.sessionID}`);
          const comments = ref.child('contents');
          comments.on('child_added', (dataSnapshot) => {
            const commentChanged = dataSnapshot.val();
            if (commentChanged.status === 'approved') {
              setTimeout(() => {
                const out = document.getElementById('out');
                if (Math.ceil(out.scrollTop) === 0) {
                  this.newMessageFlag = true;
                  setTimeout(() => {
                    this.newMessageFlag = false;
                  }, 1000);
                }
                const middle = Math.ceil(out.scrollHeight / 2);
                if (Math.ceil(out.scrollTop) <= middle) {
                  this.newMessageFlag = true;
                  setTimeout(() => {
                    this.newMessageFlag = false;
                  }, 10000);
                }
                const check = Math.ceil(out.scrollHeight - out.clientHeight);
                if (Math.ceil(out.scrollTop) >= check) {
                  this.newMessageFlag = false;
                }
              }, 200);
            }
          });
        }
      }, 200);
    },
    handleScroll() {
      setTimeout(() => {
        const out = document.getElementById('out');
        if (out.scrollHeight < 1200) {
          this.scrollToBottom = true;
        } else {
          if (Math.ceil(out.scrollTop) === 0) {
            this.scrollToBottom = false;
            setTimeout(() => {
              this.scrollToBottom = true;
              this.scrolling();
            }, 20000);
            console.log('Top', this.scrollToBottom);
          }
          const middle = Math.ceil(out.scrollHeight / 2);
          if (Math.ceil(out.scrollTop) <= middle) {
            this.scrollToBottom = false;
            setTimeout(() => {
              this.scrollToBottom = true;
              this.scrolling();
            }, 20000);
            console.log('Middle', this.scrollToBottom);
          }
          const check = Math.ceil(out.scrollHeight - out.clientHeight);
          if (Math.ceil(out.scrollTop) >= check) {
            this.scrollToBottom = true;
            this.scrolling();
            console.log('Bottom', this.scrollToBottom);
          }
        }
      }, 300);
    },
    scrolling() {
      setTimeout(() => {
        const out = document.getElementById('out');
        if (this.scrollToBottom === false || out === null) {
          console.log('Do not hit bottom');
        } else {
          out.scrollTop = out.scrollHeight - out.clientHeight;
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  /*FireFox*/
  scrollbar-width: none !important;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
}
.presentor-view {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .presentor-container {
    width: 95%;
    max-width: 1400px;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px solid lightgrey;
    height: 97vh;
    max-height: 97vh;
    overflow: hidden;

    .event-title {
      width: 100%;
      height: auto;
      padding: 15px 10px 15px 10px;

      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      p {
        font: 600 1.2rem "Helvetica Neue", sans-serif;
        color: #f3f3f3;
      }
    }

    .comments-section {
      width: 100%;
      max-height: 90vh;
      flex-grow: 1;
      padding: 10px;

      .comment-title {
        width: 100%;
        height: auto;
        padding: 5px 0 5px 0;

        p {
          font: 600 1.1rem "Helvetica Neue", sans-serif;
          color: #f3f3f3;
        }
      }

      .new-message {
        width: 100%;
        height: auto;
        padding: 5px 0 5px 0;

        background: #f1c40f;

        text-align: center;
        font-size: 1.1rem;
        font-weight: bold;
        border-radius: 5px;
      }

      ul {
        width: 100%;
        max-height: 85vh;
        overflow: scroll;
        padding-bottom: 40px;

        &::-webkit-scrollbar {
          display: none;
        }

        .comment {
          width: 100%;
          height: auto;
          margin-top: 10px;

          display: flex;
          flex-direction: row;
          align-items: flex-start;
          align-content: center;
          justify-content: flex-start;
          background: transparent;

          border-bottom: 1px solid lightgray;

          .new-message {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #e74c3c;
          }

          .comment-name {
            width: 100%;
            height: auto;

            color: hsl(0, 0%, 20%);
            font: 600 12px "Helvetica Neue", sans-serif;
            text-transform: capitalize;
            padding: 0 0 1px 0;
            margin-bottom: 2px;
            display: flex;
            align-items: center;
            align-content: center;
          }

          .comment-content {
            width: 100%;
            max-width: 99%;
            height: auto;

            font-size: .975rem;
            letter-spacing: 0.05px;
            line-height: 1.65;
            word-wrap: break-word;
            color: #333333;

            float: left;
            display: inline-block;
            position: relative;
          }
        }
      }
    }
  }
}
</style>
